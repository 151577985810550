import { memo } from "react"
import { NavLink } from "./NavLink"
import { HiHome, HiSearch } from "react-icons/hi"
import { IoIosAppstore } from "react-icons/io"
import { BiNews } from "react-icons/bi"
import { RiSettings4Fill } from "react-icons/ri"
import useT from "../utils/useT"
import useGlobalStore from "../stores/global"

const NavBar = () => {
  const t = useT()
  const transparency = useGlobalStore((state) => state.transparency)

  return (
    <div
      id="footer-bar"
      className={`footer-bar-5 ${transparency ? "transparency" : ""}`}
    >
      <NavLink
        href="/?page=apps"
        as="/apps"
        title="apps"
        exact
        className="navbar-item"
      >
        <IoIosAppstore className="nav-icon" />
        <div className="navbar-name">{t.navBar.apps}</div>
      </NavLink>
      <NavLink
        href="/?page=search"
        as="/search"
        title="search"
        exact
        className="navbar-item"
      >
        <HiSearch className="nav-icon" />
        <div className="navbar-name">{t.navBar.search}</div>
      </NavLink>
      <NavLink
        href="/?page=home"
        as="/"
        title="home"
        exact
        className="navbar-item"
      >
        <HiHome className="nav-icon" />
        <div className="navbar-name">{t.navBar.home}</div>
      </NavLink>
      <NavLink
        href="/?page=feed"
        as="/feed"
        title="feed"
        exact
        className="navbar-item"
      >
        <BiNews className="nav-icon" />
        <div className="navbar-name">{t.navBar.feed}</div>
      </NavLink>
      <NavLink
        href="/?page=settings"
        as="/settings"
        title="settings"
        exact
        className="navbar-item"
      >
        <RiSettings4Fill className="nav-icon" />
        <div className="navbar-name">{t.navBar.settings}</div>
      </NavLink>
    </div>
  )
}

export default memo(NavBar)
